<template>
  <div id="app">
    <h1>Изюм</h1>
    <ServiceStatus></ServiceStatus>
	<a href="http://servicemonitoringizum.ru:3000/">grafana</a>
  </div>
</template>

<script>
import ServiceStatus from "@/components/ServiceStatus";
import Vue from 'vue';
import moment from 'moment';
Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY hh:mm:ss')
  }
});

export default {
  name: 'App',
  components: {
    ServiceStatus,
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
