<template>
<div class="services_status">

  <div class="services">
    <div class="service" v-for="service in services" :key="service.name">
      <div class="service-head">Name: <b>{{ service.name }}</b></div>
      <div class="service-host">Host: <a :href="service.host" target="_blank"><b>{{ service.host }}</b></a></div>
      <div class="status" v-bind:class="isOk(service)">{{ service.status }}</div>
      <div>Last Update: {{ service.last_update | formatDate }}</div>
      <div>Last OK: {{ service.last_ok | formatDate }}</div>
      <div>Last Fall: {{ service.last_not_ok | formatDate }}</div>
    </div>
  </div>
  <div class="services">
    <div class="service" v-for="state in states" :key="state.service_name">
      <div class="service-head">Name: <b>{{ state.service_name }}</b></div>
      <div class="service-host">Host: <a :href="state.url" target="_blank"><b>{{ state.url }}</b></a></div>
      <div>CPU usage: {{ state.cpu_usage }}%</div>
      <div>RAM usage: {{ state.usage_memory }}%</div>
      <div>Storage size: {{ state.disk }}GB</div>
      <div>Storage free: {{ state.disk_free }}GB</div>
      <div>Storage usage: {{ state.disk_usage }}%</div>
      
      <div>Last Update: {{ state.update_time | formatDate }}</div>
    </div>
  </div>

<!--  <ul>-->
<!--    <li class="status-row" v-for="service in services" :key="service.name">-->
<!--      Name: <span><b>{{ service.name }}</b></span>,-->
<!--      Host: <span><b>{{ service.host }}</b></span>,-->
<!--      Status: <span class="status" v-bind:class="isOk(service)">{{ service.status }}</span>-->
<!--      Last Update: <span><b>{{ service.last_update | formatDate }}</b></span>,-->
<!--      Last OK: <span><b>{{ service.last_ok | formatDate }}</b></span>,-->
<!--    </li>-->
<!--  </ul>-->
</div>
</template>



<script>
export default {
name: "ServiceStatus",
data() {
  return {
    services: [
      {
        name: "",
        host: "",
        status: 0,
        last_update: new Date(),
        last_ok: new Date(),
        last_not_ok: new Date(),
      }
    ],
    states: [
      {
        id: "",
        service_name: "",
        url: "",
        cpu_usage: 50,
        usage_memory: 50,
        disk: 100,
        disk_free: 50,
        disk_usage: 50,
        update_time: new Date(),
      }
    ],
  }
},
  mounted: function () {

  },
  created() {
    this.check_status()
    this.get_states()
    window.setInterval(() => {
      this.check_status()
    }, 1000)
    window.setInterval(() => {
      this.get_states()
    }, 10000)
  },
  methods: {
    check_status: function () {
      fetch("https://servicemonitoringizum.ru/api")
          .then(response => response.json())
          .then(data => (this.services = data));
          // .then(data => (console.log(data)))
    },
    isOk: function (service) {
      if (service.status === 200) {
        return "ok"
      }
      return "error"
    },
    get_states: function() {
      fetch("https://servicemonitoringizum.ru/service_state/")
          .then(response => response.json())
          .then(data => (this.states = data))
    }
  }
}
</script>

<style scoped>
  .status {
    font-weight: 900;
    padding: 5pt;
    border-radius: 5px;
  }

  /*.status-row{*/
  /*  padding: 10pt;*/
  /*  list-style-type: none;*/
  /*  color: black;*/
  /*}*/

  .service {
    max-width: 400px;
    min-width: 200px;
    padding: 10px;
    margin: 10px;
    background: #e8e8e8;
    border-radius: 25px;
    font-size: 10pt;
    width: 20%;
  }
  .service-head {
    font-size: 14pt;
  }
  .services {
    display: flex;
    flex-wrap: wrap;
    max-width: 1500px;
    min-width: 400px;
    margin: 0 auto;
    width: 80%;
    justify-content: center;
  }
 .ok {
   background-color: #1fea09;
 }
 .error {
   background-color: #d90e0f;
 }
  .vertical-center {
    vertical-align: center;
  }
</style>
